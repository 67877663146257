[
  "a",
  "am",
  "an",
  "and",
  "as",
  "at",
  "by",
  "c's",
  "co",
  "do",
  "eg",
  "et",
  "for",
  "he",
  "hi",
  "i",
  "i'd",
  "i'm",
  "ie",
  "if",
  "in",
  "inc",
  "is",
  "it",
  "its",
  "me",
  "my",
  "nd",
  "no",
  "non",
  "nor",
  "not",
  "of",
  "off",
  "oh",
  "ok",
  "on",
  "or",
  "per",
  "que",
  "qv",
  "rd",
  "re",
  "so",
  "sub",
  "t's",
  "th",
  "the",
  "to",
  "too",
  "two",
  "un",
  "up",
  "us",
  "vs",
  "we"
]
