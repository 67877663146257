const _temp0 = require("../images/MusicBrainz_logo.svg");

const _temp1 = require("../images/amazon_icon.svg");

const _temp2 = require("../images/apple_music_icon.svg");

const _temp3 = require("../images/archive_icon.svg");

const _temp4 = require("../images/bandcamp_icon.svg");

const _temp5 = require("../images/deezer_icon.svg");

const _temp6 = require("../images/generic_song_link_icon.svg");

const _temp7 = require("../images/google_play_icon.svg");

const _temp8 = require("../images/itunes_icon.svg");

const _temp9 = require("../images/opengraph_banner.png");

const _temp10 = require("../images/soundcloud_icon.svg");

const _temp11 = require("../images/spotify_icon.svg");

const _temp12 = require("../images/tidal_icon.svg");

const _temp13 = require("../images/typesense.svg");

const _temp14 = require("../images/youtube_icon.svg");

module.exports = {
  "MusicBrainz_logo":   {
    "svg": _temp0
  },
  "amazon_icon":   {
    "svg": _temp1
  },
  "apple_music_icon":   {
    "svg": _temp2
  },
  "archive_icon":   {
    "svg": _temp3
  },
  "bandcamp_icon":   {
    "svg": _temp4
  },
  "deezer_icon":   {
    "svg": _temp5
  },
  "generic_song_link_icon":   {
    "svg": _temp6
  },
  "google_play_icon":   {
    "svg": _temp7
  },
  "itunes_icon":   {
    "svg": _temp8
  },
  "opengraph_banner":   {
    "png": _temp9
  },
  "soundcloud_icon":   {
    "svg": _temp10
  },
  "spotify_icon":   {
    "svg": _temp11
  },
  "tidal_icon":   {
    "svg": _temp12
  },
  "typesense":   {
    "svg": _temp13
  },
  "youtube_icon":   {
    "svg": _temp14
  }
}